import Vue from "vue"

import {basicAxios,refreshAxios} from '@utils/axiosUtil.js'
import axios from 'axios'
import {replaceProperty,clearProperty} from '@utils/objectUtil.js'
import {isStringEmpty} from '@utils/stringUtil.js'
import ApiError from '@utils/errors/ApiError'
import dispatchActionForAllModules from "../dispatch-action-for-all-modules"
import { unpackRules } from '@casl/ability/extra'
import abilities from '../../authorize/abilities'
import router from '../../router/index';


export const state = {
	accessToken : null,
	currentUser: {
		id : null,
		company_id : null,
		username : null,
		full_name: null,
		email : null,
		phone : null,
		user_role: null,
		image_url : null,
		previous_access_datetime : null
	} ,
	currentCompany: {
		id : null,
		name : null,
		company_type : null,
		image_url : null,
		access_company_list: null,
	} ,
	currentLineAccount : {
		line_id : null,
		display_name : null,
		profile_image_url : null,
		add_friend_status : null,
		created_datetime : null
	}
};

export const mutations = {
	INITIAL_STORE(state) {
		state.accessToken = Vue.ls.get("auth.accessToken",null);
		if (state.accessToken)
			setAbilities(state.accessToken)
		const storeUser = JSON.parse(Vue.ls.get("auth.currentUser","{}"));
		const storeCompany = JSON.parse(Vue.ls.get("auth.currentCompany","{}"));
		const storeLineAccount = JSON.parse(Vue.ls.get("auth.currentLineAccount","{}"));
		replaceProperty(state.currentUser,storeUser)
		replaceProperty(state.currentCompany,storeCompany)
		replaceProperty(state.currentLineAccount,storeLineAccount)
		setDefaultAuthHeaders(state.accessToken);
	} ,
	CHANGE_TOKEN(state,{token}) {
		if (state.accessToken !== token) {
			state.accessToken = token;
			setDefaultAuthHeaders(state.accessToken);
		}
	} ,
	SET_NEW_ACCESS_TOKEN(state, {token,expiresIn}) {
		state.accessToken = token;
		Vue.ls.set("auth.accessToken",token);
		setDefaultAuthHeaders(state.accessToken);
	} ,
	SET_NEW_REFRESH_TOKEN(state,{token,expiresIn}) {
		Vue.ls.set("auth.refreshToken",token);
	} ,
	INITIAL_USER_DATA(state,payload) {
		replaceProperty(state.currentUser,payload.user)
		replaceProperty(state.currentCompany,payload.company)
		replaceProperty(state.currentLineAccount,payload.line_account)
		Vue.ls.set("auth.currentUser",JSON.stringify(state.currentUser));
		Vue.ls.set("auth.currentCompany",JSON.stringify(state.currentCompany));
		Vue.ls.set("auth.currentLineAccount",JSON.stringify(state.currentLineAccount));
	} ,
	UPDATE_USER_DATA(state,payload) {
		replaceProperty(state.currentUser,payload.user)
		Vue.ls.set("auth.currentUser",JSON.stringify(state.currentUser));
	} ,
	UPDATE_LINE_ACCOUNT_DATA(state,payload) {
		replaceProperty(state.currentLineAccount,payload.line_account)
		Vue.ls.set("auth.currentLineAccount",JSON.stringify(state.currentLineAccount));
	} ,
	UPDATE_COMPANY_DATA(state,payload) {
		replaceProperty(state.currentCompany,payload.company)
		Vue.ls.set("auth.currentCompany",JSON.stringify(state.currentCompany));
	} ,
	CLEAR_AUTH(state) {
		state.accessToken = null;
		clearProperty(state.currentUser)
		clearProperty(state.currentCompany)
		clearProperty(state.currentLineAccount)
		Vue.ls.remove("auth.currentUser")
		Vue.ls.remove("auth.currentCompany")
		Vue.ls.remove("auth.currentLineAccount")
		Vue.ls.remove("auth.accessToken")
		Vue.ls.remove("auth.refreshToken")
		Vue.ls.remove("dashboard.showOnlyCompany")
	}
}

export const getters = {
  isLoggedIn(state) {
		return state.accessToken && state.accessToken !== "";
	} ,
	authorizeDomain(state,getters) {
		if (!getters.isLoggedIn)
			return {
				isLoggedIn : false
			}
		else
			return {
				isLoggedIn : true ,
				userId : state.currentUser.id ,
				companyId : state.currentCompany.id,
				userRole : state.currentUser.user_role,
				companyType : state.currentCompany.company_type,
			}
	}
}

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	init({ state, commit }) {
		if (state.isLoggedIn)
			return
		commit('INITIAL_STORE');
		// Watch change for access token for others tab
		Vue.ls.on('auth.accessToken',(val,oldVal,uri)=> {
			commit('CHANGE_TOKEN',{ token:val });
		})
  },
	loginAndLine({commit},payload) {
		return new Promise( (resolve,reject) => {
			basicAxios.post('/api/auth/login-and-line',payload).then((loginResponse)=>{
				const loginData = loginResponse.data.data;
				setDefaultAuthHeaders(loginData.access_token)

				axios.get('/api/token/initial').then((initialResponse) => {
					const initialData = initialResponse.data.data;

					let lineAccount = {}
					if (initialData.line_account && initialData.line_account.line_id) {
						lineAccount = initialData.line_account
					}
					commit("SET_NEW_ACCESS_TOKEN",{
						token : loginData.access_token,
						expiresIn: loginData.expires_in
					})
					commit("SET_NEW_REFRESH_TOKEN",{
						token : loginData.refresh_token ,
						expiresIn : 0
					})
					commit("INITIAL_USER_DATA",{
						user : initialData.user, company: initialData.company,line_account : lineAccount
					})
					dispatchActionForAllModules('init')
					resolve();
				})
			}).catch((error) =>{
				reject(error);
			})
		});
	} ,
	loginWithLine({commit,dispatch,getters},{code,state}) {
		return new Promise((resolve,reject)=>{
			basicAxios.post('/api/auth/login-with-line',{code,state}).then((loginResponse) => {
				const loginData = loginResponse.data.data
				if (loginData.access_token) {
					// success
					setDefaultAuthHeaders(loginData.access_token)

					axios.get('/api/token/initial').then((initialResponse) => {
						const initialData = initialResponse.data.data;

						let lineAccount = {}
						if (initialData.line_account && initialData.line_account.line_id) {
							lineAccount = initialData.line_account
						}
						commit("SET_NEW_ACCESS_TOKEN",{
							token : loginData.access_token,
							expiresIn: loginData.expires_in
						})
						commit("SET_NEW_REFRESH_TOKEN",{
							token : loginData.refresh_token ,
							expiresIn : 0
						})
						commit("INITIAL_USER_DATA",{
							user : initialData.user, company: initialData.company,line_account : lineAccount
						})
						dispatchActionForAllModules('init')
						resolve({success:true});
					})
				} else {
					resolve({success:false,'line_profile' : loginData.line_profile});
				}
			}).catch((error) =>{
				reject(error);
			})
		})
	} ,
  login({commit,dispatch,getters},{username,password}) {
    return new Promise( (resolve,reject) => {
			  basicAxios.post('/api/auth/login',{
          username,
          password
        }).then((loginResponse)=>{
					const loginData = loginResponse.data.data;
					setDefaultAuthHeaders(loginData.access_token)
					if (loginData.access_token)
						setAbilities(loginData.access_token)
					axios.get('/api/token/initial').then((initialResponse) => {
						const initialData = initialResponse.data.data;
						let lineAccount = {}
						if (initialData.line_account && initialData.line_account.line_id) {
							lineAccount = initialData.line_account
						}
						commit("SET_NEW_ACCESS_TOKEN",{
							token : loginData.access_token,
							expiresIn: loginData.expires_in
						})
						commit("SET_NEW_REFRESH_TOKEN",{
							token : loginData.refresh_token ,
							expiresIn : 0
						})
						commit("INITIAL_USER_DATA",{
							user : initialData.user, company: initialData.company,line_account : lineAccount
						})
						dispatchActionForAllModules('init')
						resolve();
					})
				}).catch((error) =>{
					reject(error);
				})
    });
	} ,

	refreshToken({state,commit,getters}) {
		// TODO Can be improved to make sure that only 1 request calling
		if (!getters.isLoggedIn)
			return Promise.resolve(false);

		const refreshToken = Vue.ls.get("auth.refreshToken",null);
		if (isStringEmpty(refreshToken))
			return Promise.resolve(false);

		console.log("Refresh Token")
		return new Promise((resolve,reject)=> {
			refreshAxios.post('/api/auth/refresh-token',{
				access_token : state.accessToken,
				refresh_token : refreshToken
			}).then((response) => {
				const data = response.data.data;
				commit("SET_NEW_ACCESS_TOKEN",{
					token : data.access_token,
					expiresIn: data.expires_in
				})
				if (data.refreshToken !== refreshToken) {
					commit("SET_NEW_REFRESH_TOKEN",{
						token : data.refresh_token ,
						expiresIn : 0
					})
				}
				resolve(data.access_token);
			}).catch((error) => {
				reject(error);
			})
		})
	} ,

	// Validates the current user's token and refreshes it
	// with new data from the API.
	validate({ commit, state ,getters}) {
		if (!getters.isLoggedIn)
			return Promise.resolve(false);

		return new Promise((resolve,reject) => {
			// Mark axios config with tj_validate to prevent refresh token recall validate again after refresh
			axios.get("/api/token/validate",{"tj_validate" : true})
				.then((response) => {
					resolve(true);
				}).catch((error) => {
					console.log("Validation Error ",error)
					commit('CLEAR_AUTH');
					if (error instanceof ApiError)
						resolve(false);
					else
						reject(error)
				})
		});
	},

	logout({commit,state,getters}) {
		if (!getters.isLoggedIn)
			return Promise.resolve()

		return new Promise((resolve,reject) => {
			axios.post("/api/token/logout")
				.finally(() => {
					// No Need to examine response
					commit('CLEAR_AUTH');
					resolve();
				})
		})
	} ,

	updateCurrentLineAccount({commit,state},lineAccount) {
		if (!lineAccount.line_id) {
			commit('UPDATE_LINE_ACCOUNT_DATA',{line_account : {
				line_id : null,
				display_name : null,
				profile_image_url : null,
				add_friend_status : null,
				created_datetime : null
			}})
		} else {
			commit('UPDATE_LINE_ACCOUNT_DATA',{line_account : lineAccount});
		}
	} ,
	updateCurrentUser({commit,state},user) {
		if (user && user.id === state.currentUser.id)
			commit('UPDATE_USER_DATA',{user : user});
	} ,
	updateCurrentUserCompany({commit,state},company) {
		if (company && company.id === state.currentCompany.id)
			commit('UPDATE_COMPANY_DATA',{company : company});
	}


}

// ===
// Private helpers
// ===
function setDefaultAuthHeaders(token) {
	if (token)
		axios.defaults.headers.common.Authorization = 'Bearer '+token
	else
		axios.defaults.headers.common.Authorization = null
}
function setAbilities(token) {
	try {
		const userData = Buffer.from(token.split('.')[1], 'base64').toString()
		const rulesObj = JSON.parse(userData)
		const rules = rulesObj.rules
		abilities.update(unpackRules(rules))
	}
	catch (err) {
		Vue.ls.remove("auth.currentUser")
		Vue.ls.remove("auth.currentCompany")
		Vue.ls.remove("auth.currentLineAccount")
		Vue.ls.remove("auth.accessToken")
		Vue.ls.remove("auth.refreshToken")
		Vue.ls.remove("dashboard.showOnlyCompany")
		router.push({name: 'login'});
	}
}
