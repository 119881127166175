<template>
	<span :aria-hidden="decorative"
		:aria-label="title"
		class="material-design-icon drone-icon"
		role="img"
		v-bind="$attrs"
		v-on="$listeners">
    <DroneSvg :fill="fillColor"
				class="material-design-icon__svg"
				:width="size"
				:height="size">
    </DroneSvg>
	</span>
</template>
<script>
import DroneSvg from '@assets/images/icon-drone.svg'
export default {
	name : "DroneIcon" ,
	components : {
		DroneSvg ,
	} ,
	props: {
		title: {
			type: String,
			default: ""
		},
		decorative: {
			type: Boolean,
			default: false
		},
		fillColor: {
			type: String,
			default: "currentColor"
		},
		size: {
			type: Number,
			default: 24
		}
	}
}
</script>
