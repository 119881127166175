<template>
	<div :class="cssClasses">
    <a-spin v-if="!hideLoading && status === 'loading'" class="image-loader-loading">
       <a-icon slot="indicator" type="loading" spin />
    </a-spin>
    <template v-if="!hasSource || status === 'failed' || (status === 'loading' && showPlaceholderLoading)">
      <template v-if="hasSlot">
        <div class="image-loader-placeholder">
          <slot></slot>
        </div>
      </template>
      <template v-else-if="hasPlaceholder">
        <img :src="placeholder" />
      </template>
    </template>

    <img v-if="status === 'loaded'" :src="src" @click="clickImage"/>
  </div>
</template>

<script>
import { isStringEmpty } from '@utils/stringUtil';
const Status = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}
export default {
  props : {
    src : {
      type: String,
      default : null
    } ,
    hideLoading: {
      type: Boolean ,
    } ,
    showPlaceholderLoading: {
      type: Boolean ,
      default: false
    } ,
    size: {
      type: String,
      required: false,
      default : null,
    } ,
    borderType : {
      type: String,
      required: false ,
      default : 'normal' ,
      validator: function (value) {
        return ['normal','round','circle'].indexOf(value) !== -1
      }
    } ,
    placeholder : {
      type: String ,
      required: false ,
      default: null ,
    }
  } ,
	data() {
		return {
      status: null,
      image: null ,
		}
  } ,
  computed : {
    hasSlot() {
      return this.$slots.default != null;
    } ,
    hasSource() {
      return !isStringEmpty(this.src)
    } ,
    hasPlaceholder() {
      return !isStringEmpty(this.placeholder)
    } ,
    cssClasses() {
      const classes = ['image-loader'];
      if (!isStringEmpty(this.size))
        classes.push('image-size-'+this.size);
      return [
        classes ,
        {
          'image-circle' : this.borderType === 'circle' ,
          'image-round' : this.borderType === 'round' ,
        }
      ];
    }
  } ,
  watch : {
    src(newSrc,oldSrc) {
      if (isStringEmpty(newSrc)) {
        this.destroyImageLoader();
        this.status = Status.FAILED
      } else {
        this.status = Status.LOADING;
        this.createImageLoader();
      }
    }
  } ,
  created() {
    if (this.hasSource) {
      this.status = Status.LOADING;
      this.createImageLoader();
    } else {
      this.status = Status.FAILED; // Use placeholder instead
    }
  } ,
  beforeDestroy() {
    this.destroyImageLoader();
  } ,
  methods : {
    createImageLoader() {
      this.destroyImageLoader();
      this.image = new Image();
      this.image.onload = this.handleLoader;
      this.image.onerror = this.handleError;
      this.image.src = this.src;
    } ,
    destroyImageLoader() {
      if (this.image) {
        this.image.onload = null;
        this.image.onerror = null;
        this.image = null;
      }
    } ,
    handleLoader() {
      this.destroyImageLoader();
      this.status = Status.LOADED;
      this.$emit('onLoad');
    } ,
    handleError(error) {
      this.destroyImageLoader();
      this.status = Status.FAILED;
      this.$emit('onError',error)
    } ,
    clickImage() {
      this.$emit('click',this.src)
    }
  } ,

}
</script>

<style lang="less">

</style>
