<template>
	<router-link v-if="cClickable" :to="cRoute" v-bind="$attrs" :target="cTarget">
		<slot />
	</router-link>
	<span v-else v-bind="$attrs" v-on="$listeners">
		<slot />
	</span>
</template>

<script>
export default {
	props : {
		authAction : {
			type : String,
			default: null
		} ,
		authModule : {
			type : String,
			default: null
		} ,
		name : {
			type : String,
			default: null
		} ,
		paramValue : {
			type : null,
			default: null
		} ,
		paramName : {
			type : String,
			default : 'id'
		} ,
		newWindow : {
			type : Boolean,
			default : false
		} ,
		noParam : {
			type : Boolean,
			default : false,
		} ,
		target : {
			type : String,
			default : undefined
		} ,
		clickable : {
			type : Boolean,
			default : true,
		}
	} ,
	computed : {
		cTarget() {
			if (this.newWindow) {
				return "_blank"
			} else {
				return this.target
			}
		} ,
		cClickable() {
			return this.hasPermission() && this.clickable && this.name && (this.noParam || this.paramValue)
		} ,
		cRoute() {
			const route = {name : this.name}
			if (!this.noParam) {
				route.params ={[this.paramName] : this.paramValue}
			}
			return route;
		} ,
	},
	methods: {
		hasPermission() {
			if (!this.authAction || !this.authModule)
				return true

			return this.$authorize(this.authAction, this.authModule)
		} ,
	}
}
</script>
