import enquireJs from 'enquire.js'

export const DEVICE_TYPE = {
  DESKTOP : 'desktop' ,
  MOBILE : 'mobile' ,
  TABLET : 'tablet'
}

export const deviceEnquiry = function (callback) {
  const matchDesktop = {
    match: () => {
      document.body.setAttribute('data-device',DEVICE_TYPE.DESKTOP)
      callback && callback(DEVICE_TYPE.DESKTOP)
    }
  }

  const matchTablet = {
    match: () => {
      document.body.setAttribute('data-device',DEVICE_TYPE.TABLET)
      callback && callback(DEVICE_TYPE.TABLET)
    }
  }

  const matchMobile = {
    match: () => {
      document.body.setAttribute('data-device',DEVICE_TYPE.MOBILE)
      callback && callback(DEVICE_TYPE.MOBILE)
    }
  }

  // screen and (max-width: 1087.99px)
  enquireJs
    .register('screen and (max-width: 575px)', matchMobile)
    .register('screen and (min-width: 576px) and (max-width: 1199px)', matchTablet)
    .register('screen and (min-width: 1200px)', matchDesktop)
}
