import Vue from "vue"
import axios from "axios"
import debounce from "lodash/debounce"
import { fulfillUser ,emptyUser} from '@utils/objectUtil'

export const state = {
	userDatas : [] ,
	companies : [] ,
}
export const getters = {
	getUserDataById: (state) => (id) => {
		if (!id || id <= 0) {
			return emptyUser()
		} else {
			return state.userDatas.find(user => user.id == id);
		}
	} ,
	getCompanyById : (state) => (id) => {
		if (!id || id <= 0) {
			return {}
		} else {
			return state.companies.find(c => c.id == id);
		}
	} ,
}
export const mutations = {
	GET_FROM_LS(state) {
		state.userDatas = Vue.ls.get('user.userDatas',[]);
		state.companies = Vue.ls.get('user.companies',[]);
	} ,
	UPDATE_USERS(state,payload) {
		state.userDatas = payload.userDatas
		Vue.ls.set('user.userDatas',payload.userDatas,30*60*1000)
	},
	UPDATE_COMPANIES(state,payload) {
		state.companies = payload.companies
		Vue.ls.set('user.companies',payload.companies,30*60*1000)
	},
}
export const actions = {
	init({commit,dispatch}) {
		commit('GET_FROM_LS')
		dispatch('fetchUsers')
		dispatch('fetchCompanies')
	} ,
	async _fetchUsers({commit,rootGetters}){
		const authorizeDomain = rootGetters['auth/authorizeDomain']
		if (!authorizeDomain.isLoggedIn)
			return Promise.resolve()
		return axios.get("/api/users/search",{
			params : {filter : {status : 'active',mode : 'company'}}
		}).then((response) => {
			const userDatas = response.data.data.users.map((user) => {
				return fulfillUser(user,this.dataBucket)
			})
			commit('UPDATE_USERS',{userDatas})
		}).catch((error) => {
			console.log("Found error during request users ",error)
		})
	} ,
	async _fetchCompanies({commit,rootGetters}){
		const authorizeDomain = rootGetters['auth/authorizeDomain']
		if (!authorizeDomain.isLoggedIn)
			return Promise.resolve()
		return axios.get("/api/companies/").then((response) => {
			const companies = response.data.data.companies
			commit('UPDATE_COMPANIES',{companies})
		}).catch((error) => {
			console.log("Found error during request companies ",error)
		})
	} ,
	fetchUsers : debounce(function({dispatch}){
		dispatch('_fetchUsers');
	},300,{leading:false}) ,
	fetchCompanies : debounce(function({dispatch}){
		dispatch('_fetchCompanies');
	},300,{leading:false})
}
