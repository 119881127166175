import Vue from "vue"
import axios from "axios"

export const state = {
	country : process.env.VUE_APP_COUNTRY ,
	country_short : process.env.VUE_APP_COUNTRY_SHORT,
	provinces : [] ,
}
export const getters = {
	hasProvinces(state) {
		return state.provinces.length > 0
	} ,
	provincesGroupByRegion(state,getters) {
		const regions = {};
		if (getters.hasProvinces) {
			state.provinces.forEach(province => {
				if (!regions[province.region]) {
					regions[province.region] = []
				}
				regions[province.region].push(province)
			});
		}
		return regions
	} ,
	getProvinceByName : (state) => (name) => {
		return state.provinces.find(province => province.name === name)
	}
}

export const mutations = {
	SET_COUNTRY(state,payload) {
		state.country = payload.country;
		state.country_short = payload.country_short;
	} ,
	UPDATE_PROVINCES(state,payload) {
		Vue.set(state,'provinces',payload.provinces)
	} ,
	UPDATE_PROVINCES_LS(state,payload) {
		// Set expire 1 day
		Vue.ls.set("data.provinces."+state.country_short,payload.provinces,24*60*60*1000)
	}
}
export const actions = {
	init({commit,dispatch}) {
		dispatch('fetchProvinces')
	} ,

	async fetchProvinces({state,commit,rootGetters}) {
		if (!rootGetters['auth/isLoggedIn'])
			return Promise.resolve();

		console.log("Fetch Provinces "+process.env.VUE_APP_COUNTRY)
		if (state.provinces.length != 0) {
			return Promise.resolve(state.provinces)
		}

		// Get from ls
		const storeProvinces = Vue.ls.get("data.provinces."+state.country_short,[]);
		if (storeProvinces.length == 0) {
			console.log("Fetch Provinces - From Server "+state.country)
			// retrive from server
			return axios.get("/api/data/provinces",{
				params: {
					country: state.country
				}
			}).then((response) => {
				const provinces = response.data.data.provinces
				commit('UPDATE_PROVINCES_LS',{provinces : provinces})
				commit('UPDATE_PROVINCES',{provinces : provinces})
				return provinces
			}).catch((error) => {
				console.log("Found error during request provinces ",error)
			})
		} else {
			console.log("Fetch Provinces - From LS ")
			commit('UPDATE_PROVINCES',{provinces : storeProvinces})
			return Promise.resolve(storeProvinces)
		}
	}
}
