
<i18n locale="th" lang="yaml" src="@i18n/common/breadcrumb.th.yaml">
</i18n>

<template>
	<div v-if="showBreadcrumb" class="mybreadcrumb">
		<span v-if="items === null">
			<a-spin size="small" /> {{$t('breadcrumb.loading')}}
		</span>
		<a-breadcrumb v-else>
			<a-breadcrumb-item href="/">
				<my-router-link name="dashboard" no-param>
					<a-icon type="home" />
				</my-router-link>
			</a-breadcrumb-item>

			<a-breadcrumb-item v-for="(item, index) of items" :key="index">
				<router-link v-if="item.to" :to="item.to" >
					<a-icon v-if="$notEmpty(item.icon)" :type="item.icon" />
					<span v-if="$notEmpty(item.title)" :title="item.title" class="mybreadcrumb-item-ellipsis">
						{{item.title}}
					</span>
				</router-link>
				<template v-else>
					<a-icon v-if="$notEmpty(item.icon)" :type="item.icon" />
					<span v-if="$notEmpty(item.title)" :title="item.title">
						{{item.title}}
					</span>
				</template>
			</a-breadcrumb-item>
		</a-breadcrumb>
	</div>
</template>

<script>
import get from "lodash/get"
import {isStringEmpty} from "@utils/stringUtil"
import {Breadcrumb} from "ant-design-vue"

export default {
	components : {
		"a-breadcrumb" : Breadcrumb ,
		"a-breadcrumb-item" : Breadcrumb.Item
	} ,
	props : {
		titleVars : {
			type : null ,
			default : () => []
		}
	} ,
	computed : {
		showBreadcrumb() {
			return !get(this.$route,'meta.breadcrumb.hide',false)
		} ,
		items() {
			/*
			 * Meta breadcrumb
			 * 	- titleVar [Variable to display]
			 *  - title [Title Key (i18n)]
			 * 	- parent - Can be array or object
			 * 			- name : [route name]
			 * 			- routeParams
			 * 					- name
			 * 					- valueVar , value
			 * 			- title | titleVar
			 */

			// We cannot set data in computed method, and this has a lot of watch params
			// Hacky way return null for titleVar is empty for loading display
			const items = []
			for(const index in this.$route.matched) {
				const route = this.$route.matched[index]
				const breadcrumb = get(route,'meta.breadcrumb',false)
				if (!breadcrumb) {
					continue
				}

				if (isStringEmpty(route.name))
					continue
				if (get(breadcrumb,'parent',null) !== null)  {
					let parents;
					if (Array.isArray(breadcrumb.parent)) {
						parents = [...breadcrumb.parent];
					} else {
						parents = [breadcrumb.parent]
					}
					for(const parent of parents) {
						if (isStringEmpty(parent.name))
							continue
						const parentItem = this.solveItem(parent,parent.name,this.titleVars)
						if (parentItem === false)
							break;
						if (parentItem === null)
							return null;
						items.push(parentItem)
					}
				}
				if (!get(breadcrumb,'ignore',false)) {
					const item = this.solveItem(breadcrumb,route.name,this.titleVars);
					if (item === false) {
						break;
					}
					if (item === null)
						return null;
					items.push(item);
				}
			}
			if (items.length > 0)
				items[items.length-1].to = false
			return items;
		}
	} ,
	methods : {
		solveItem(obj,routeName,titleVars) {
			const titleVar = get(obj,'titleVar')
			let title = '';
			if (!isStringEmpty(titleVar)) {
				if (titleVar in titleVars) {
					title = titleVars[titleVar]
				}
				if (title === false)
					return false
				if (isStringEmpty(title))
					return null;
			} else {
				const titleKey = get(obj,'title',"breadcrumb."+routeName.replace(/[/]/g,"."))
				title = this.$t(titleKey)
			}

			const routeParams = get(obj,'routeParams')
			const params = {...this.$route.params}

			if (routeParams) {
				for(const routeParam of routeParams) {
					const paramName = get(routeParam,'name',null)
					if (isStringEmpty(paramName))
						continue
					let paramValue = get(routeParam,'value',undefined)
					if (paramValue === undefined) {
						const valueVar = get(routeParam,'valueVar')
						if (!isStringEmpty(valueVar) && valueVar in titleVars) {
							paramValue = titleVars[valueVar]
						}
					}
					params[paramName] = paramValue
				}
			}
			return {
					title ,
					to : {
						name : routeName ,
						params : params ,
					}
			};
		}
	}

}


</script>
