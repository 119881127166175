import isString from "lodash/isString"
import abilities from '../authorize/abilities';
import {COMPANY_ID} from "@/src/utils/companyUtil"
function isAdmin(store) {
	const authorizeDomain = store.getters['auth/authorizeDomain'];
	const admin = 1
	return authorizeDomain.userRole === admin;
}
function isMe(store, userId) {
	const authorizeDomain = store.getters['auth/authorizeDomain'];
	return authorizeDomain.userId === userId;
}
function isManagement(companyType) {
  return companyType === COMPANY_ID.DECK || companyType === COMPANY_ID.HGR
}
function isFlagship(companyType) {
  return companyType === COMPANY_ID.FLAGSHIP
}
function isExternalDealer(companyType) {
	return companyType === COMPANY_ID.EXTERNAL_DEALER
}
function isServiceCenter(companyType) {
	return companyType === COMPANY_ID.SERVICE_CENTER
}
function checkRolesFunc(store,checkRoles) {
	if (checkRoles === null || checkRoles.length == 0)
		return true;
	const authorizeDomain = store.getters['auth/authorizeDomain'];
	if (!authorizeDomain.isLoggedIn)
		return false;

	for(const checkRole of checkRoles) {
		if (isString(checkRole)) {
			// check current role
			if (authorizeDomain.userRole == checkRole) {
				return true;
			}
		} else {
			if (!checkRole.role && !checkRole.company) {
				continue;
			}
			if (checkRole.role && authorizeDomain.userRole != checkRole.role) {
				continue;
			}
			if (checkRole.company && authorizeDomain.companyType != checkRole.company) {
				continue;
			}
			return true;
		}
	}
	return false;
}
function isAuthorize(store,action,module,params={}) {
	const authorizeDomain = store.getters['auth/authorizeDomain'];
	let isAllowed = false;
	if (!authorizeDomain.isLoggedIn)
		return false;
	if (abilities.can(action, module)) {
		if (params.companyId && params.userId) {
			isAllowed = authorizeDomain.companyId === params.companyId && authorizeDomain.userId === params.userId;
		} else if (params.companyId) {
			isAllowed = authorizeDomain.companyId === params.companyId;
		} else if (params.userId) {
			isAllowed = authorizeDomain.userId === params.userId;
		} else {
			isAllowed = true;
		}
	}
	if (isAllowed === false) {
		if (abilities.can(action + 'All', module))
			isAllowed = true
	}
	return isAllowed;
};


export default {
	install(Vue) {
		console.log("Initialize Vue Authorize")

		Vue.$authorize = function(action,module,params={}) {
			const store = require('@store/store.js').default;
			return isAuthorize(store,action,module,params)
		}

		Vue.$checkRoles = function(checkRoles) {
			const store = require('@store/store.js').default;
			return checkRolesFunc(store,checkRoles);
		}

		Vue.$isAdmin = function() {
			const store = require('@store/store.js').default;
			return isAdmin(store);
		}

		Vue.$isMe = function(userId) {
			const store = require('@store/store.js').default;
			return isMe(store,userId);
		}

		Vue.$isManagement = function(companyType) {
			return isManagement(companyType);
		}

		Vue.$isFlagship = function(companyType) {
			return isFlagship(companyType);
		}

		Vue.$isExternalDealer = function(companyType) {
			return isExternalDealer(companyType);
		}

		Vue.$isServiceCenter = function(companyType) {
			return isServiceCenter(companyType);
		}

		Vue.prototype.$authorize = function(action,module,params={}) {
			return isAuthorize(this.$store,action,module,params)
		}
		Vue.prototype.$checkRoles = function(checkRoles) {
			return checkRolesFunc(this.$store,checkRoles);
		}
		Vue.prototype.$isAdmin = function() {
			return isAdmin(this.$store);
		}
		Vue.prototype.$isMe = function(userId) {
			return isMe(this.$store,userId);
		}
		Vue.prototype.$isManagement = function(companyType) {
			return isManagement(companyType);
		}
		Vue.prototype.$isFlagship = function(companyType) {
			return isFlagship(companyType);
		}
		Vue.prototype.$isExternalDealer = function(companyType) {
			return isExternalDealer(companyType);
		}
		Vue.prototype.$isServiceCenter = function(companyType) {
			return isServiceCenter(companyType);
		}
	} ,
}
