import isString from "lodash/isString"
import isEmpty from "lodash/isEmpty"
import isBoolean from "lodash/isBoolean"
import isNumber from "lodash/isNumber"
import trim from "lodash/trim"
import get from "lodash/get"
import numeral from "numeral"

export const RMHTMLREGEX = /<[^>]*>/g

export function convertStr(str, regex, replacement) {
	return str.replace(regex, replacement)
}

export function isStringEmpty(value) {
	if (!value || !isString(value))
		return true;
	return trim(value).length == 0
}

export function isVarEmpty(value) {
	if (value === null || value === undefined)
		return true;
	if (isBoolean(value) || isNumber(value))
		return false;
	else if (isString(value))
		return isStringEmpty(value);
	else
		return isEmpty(value);
}

export function toNumber(value) {
	if (value === null || value === undefined)
		return NaN;
	return Number(value);
}

export function stringCompare(a,b) {
	if (!isStringEmpty(a))
		return a.localeCompare(b);
	else if (isStringEmpty(b))
		return 0
	else
		return -1
}

export function addressDisplay(component,address,provinceObj,emptyValue='') {
	let rtn = ''
	if (!isStringEmpty(address)) {
		rtn += address+" "
	}
	if (provinceObj) {
		if (!isStringEmpty(provinceObj.subdistrict)) {
			rtn += provinceObj.subdistrict+" "
		}
		if (!isStringEmpty(provinceObj.district)) {
			rtn += provinceObj.district+" "
		}
		if (!isStringEmpty(provinceObj.province)) {
			rtn += component.$t('common.province')+provinceObj.province+" "
		}
		if (!isStringEmpty(provinceObj.postal_code)) {
			rtn += provinceObj.postal_code+" "
		}
	}
	if (isStringEmpty(rtn)) {
		return emptyValue;
	} else {
		return rtn;
	}
}

export function sortEnumValue(list,valueA,valueB) {
	const valA = get(list,valueA,9999)
	const valB = get(list,valueB,9999)
	return valA - valB
}

export function hoursDisplayStr(timeSeconds, hrUnitStr, minUnitStr, secUnitStr, unspecifyUnitStr) {
	if (timeSeconds > 0) {
		const hours = Math.floor(timeSeconds/(60*60))
		const left = timeSeconds - (hours * 60 *60)
		const minutes = Math.floor(left/60)
		const seconds = left - (minutes * 60)

		let rtn = ''
		if (minutes <= 0 && hours <= 0) {
			// display seconds
			rtn = seconds + " " + secUnitStr
		} else if (hours <= 0 && minutes > 0) {
			rtn = numeral(minutes).format("00") + ":" + numeral(seconds).format("00") + " " + minUnitStr
		} else {
			// display hour and minute
			rtn = numeral(hours).format("0,0") + ":" + numeral(minutes).format("00") + " " + hrUnitStr
		}
		return rtn;
	} else {
		return unspecifyUnitStr;
	}
}

export function timeRange(time) {
	if (time < 11) return 'morning'
	else if (time < 13) return 'noon'
	else if (time <= 17) return 'afternoon'
	else return 'evening'
}

const DEFAULT_MAXIMUM_LENGTH_TEXT = 24
export const wrapText = (text = '', maxLength = DEFAULT_MAXIMUM_LENGTH_TEXT) => {
	return text.length > maxLength ? text.slice(0, maxLength).concat('...') : text;
}
