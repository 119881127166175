import {isStringEmpty} from "@utils/stringUtil"
import _get from "lodash/get"

const STATUS_DOT_MODES = {
	'service' : {
		'close' : 'success',
		'cancel' : 'error',
		'wait_delivery' : 'warning'
	} ,
	'repair' : {
		'success' : 'success',
		'fail' : 'error'
	} ,
	'payment' : {
		'new' : 'warning',
		'in_progress' : 'warning',
		'complete' : 'success'
	} ,
	'claim' : {
		'approved' : 'success',
		'rejected' : 'danger',
		'appeal' : 'warning' ,
		'request' : 'warning' ,
	} ,
	'payment_request' : {
		'request' : 'warning' ,
		'complete' : 'success' ,
	}
};
const STATUS_ALLOW_VIEW_MODES = {
	'details' : ['close'] ,
	'pre_delivery' : ['pre_delivery','wait_delivery','close'] ,
	'payment' : ['in_progress','pending','pre_delivery','wait_delivery','close'] ,
	'return' : ['wait_delivery','close'] ,
	'claim' : ['in_progress','pending','pre_delivery','wait_delivery','close']
}

export const SERVICE_STATUS = {
	STATUS_NEW: 'new',
	STATUS_IN_PROGRESS: 'in_progress',
	STATUS_PENDING: 'pending',
	STATUS_PRE_DELIVERY: 'pre_delivery',
	STATUS_WAIT_DELIVERY:  'wait_delivery',
	STATUS_CLOSE: 'close',
	STATUS_CANCEL: 'cancel',
}
export const SERVICE_ONFIELD_STATUS = {
	STATE_BIND_TICKET: 'bind_ticket',
	STATE_PRESERVICE: 'pre_service',
	STATE_SERVICE_JOB: 'service_job',
	STATE_CLAIM: 'claim',
	STATE_COST_ESTIMATE: 'cost_estimate',
	STATE_PRE_DELIVERY: 'pre_delivery',
	STATE_RETURN: 'return',
	STATE_DONE: 'done',
	STATE_CANCEL: 'cancel',
}
export const CLAIM_STATUS =  {
	STATUS_DRAFT: 'draft',
	STATUS_DRAFT_APPEAL: 'draft_appeal',
	STATUS_REQUEST: 'request',
	STATUS_APPROVED: 'approved',
	STATUS_REJECTED: 'rejected',
	STATUS_APPEAL: 'appeal',
}

export const PAYMENT_STATUS = {
	STATUS_NEW: 'new',
	STATUS_IN_PROGRESS: 'in_progress',
	STATUS_COMPLETE: 'complete',
}

export const SERV_PART = 'SERV'

export const REPAIR_PART_ACTION = {
	REMOVE: 'remove',
}

export const SERVICE_PART_MODE = {
	ADD: 'add',
	CHANGE: 'change',
	REMOVE: 'remove',
}
export const SERVICE_PART_MODE_TEXT = {
	ADD: 'เพิ่ม ',
	CHANGE: 'เปลี่ยน ',
}

export const RETURN_TYPE = {
	CUSTOMER: 'customer',
	REFER: 'refer',
}

export const ROUNDUP_PER_MINUTE = 30

export const HOUR_TO_MINUTE = 60

export const VAT_PERCENT = 7

export const FULL_PERCENT = 100

export function lookupStatusDotMode(type,status) {
	if (type in STATUS_DOT_MODES && !isStringEmpty(status)) {
		if (status in STATUS_DOT_MODES[type]) {
			return STATUS_DOT_MODES[type][status]
		}
	}
	return null
}
export function isStatusAllowView(type,status) {
	if (type in STATUS_ALLOW_VIEW_MODES && !isStringEmpty(status)) {
		return STATUS_ALLOW_VIEW_MODES[type].includes(status)
	}
	return false
}
export function isReferable(service,companyId) {
	if (service.status != 'close')
		return false

	const referCompanyId = _get(service,'otherDetailsData.refer.to.company_id')
	if (!referCompanyId || referCompanyId != companyId) {
		return false
	}

	const referServiceId = _get(service,'otherDetailsData.refer.to.id')
	if (referServiceId) {
		return false // Already Refer
	}
	return true
}

export function getServiceJobsList(component,service) {
	const list = []
	if (_get(service,'otherDetailsData.repair_status') != null) {
		list.push(component.$t('service.jobs.repair'))
	}
	if (_get(service,'otherDetailsData.firmware',false)) {
		list.push(component.$t('service.jobs.firmware'))
	}
	if (_get(service,'otherDetailsData.maintenance',false)) {
		list.push(component.$t('service.jobs.maintenance'))
	}
	if (_get(service,'otherDetailsData.parts.recall',0) > 0) {
		list.push(component.$t('service.jobs.recall_parts'))
	}
	if (_get(service,'otherDetailsData.parts.normal',0) > 0) {
		list.push(component.$t('service.jobs.normal_parts'))
	}
	return list;
}
export function getOpenTypeDisplay(component,service) {
	if (service.open_type == 'from_service')	 {
		const company = _get(service,'otherDetailsData.referrer.from.company_name')
		if (!isStringEmpty(company)) {
			const serviceNo = _get(service,'otherDetailsData.referrer.from.service_no')
			if(isStringEmpty(serviceNo)) {
				return company
			} else {
				return company + " ["+serviceNo+"]"
			}
		}
	}
	return component.$tenum('service.open_type',service.open_type)
}
export function getReturnTypeDisplay(component,service) {
	const returnType = _get(service,'otherDetailsData.return.return_type')
	if (returnType == 'refer') {
		const company = _get(service,'otherDetailsData.refer.to.company_name')
		if (!isStringEmpty(company)) {
			const serviceNo = _get(service,'otherDetailsData.refer.to.service_no')
			if(isStringEmpty(serviceNo)) {
				return company
			} else {
				return company + " ["+serviceNo+"]"
			}
		}
	}
	return component.$tenum('service.return.type',returnType)
}

export const serviceModeToText = (mode) => {
	switch (mode) {
		case SERVICE_PART_MODE.ADD:
			return SERVICE_PART_MODE_TEXT.ADD
		case SERVICE_PART_MODE.CHANGE:
			return SERVICE_PART_MODE_TEXT.CHANGE
		default:
			return ''
	}
}

export const getPartsByTypeService = (parts = [], isServicePart = false) => {
	if(!parts) return []

	return parts.filter((part) => {
		if(!part || !part.partSku) return false

		return isServicePart ? part?.partSku.includes(SERV_PART) : !part?.partSku.includes(SERV_PART)
	})
}

export function calculateTechnicianFeeByTotal(total = 0, manHourPrice = 1) {
	const roundUpTotalTime = Math.ceil(total / ROUNDUP_PER_MINUTE) * ROUNDUP_PER_MINUTE
	return (roundUpTotalTime / HOUR_TO_MINUTE) * manHourPrice
}

export function formatDisplayNumber(num = 0) {
	return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function calculateVatDisplayNumber(num = 0, isVat = false) {
	const resultNum =  isVat ? num * (VAT_PERCENT / FULL_PERCENT) : num * ((FULL_PERCENT - VAT_PERCENT) / FULL_PERCENT)

	return formatDisplayNumber(resultNum)
}
