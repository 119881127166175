import Vue from "vue"
import { DEVICE_TYPE } from "@/src/utils/deviceEnquiry";
import NProgress from "nprogress/nprogress"

export const state = {
  device : DEVICE_TYPE.DESKTOP ,
  sideMenuCollapsed : false,
  sideMenuDrawerCollapsed : true,
  // screenLoading : false,
  pageLoading : false,
  blockPageLoading : false,
  breadcrumbParams : {},

  // Block Exit
  dashboardShowOnlyCompany : false,
}
export const mutations = {
  INITIAL_STORE(state) {
    state.dashboardShowOnlyCompany = Vue.ls.get("dashboard.showOnlyCompany",false)
  } ,
  CHANGE_DASHBOARD_SHOW_ONLY_COMPANY : (state, value) => {
    state.dashboardShowOnlyCompany = value
    Vue.ls.set("dashboard.showOnlyCompany",value)
  } ,
  CHANGE_DEVICE_TYPE: (state, device) => {
    state.device = device;
  } ,
  COLLAPSED_SIDE_MENU: (state, collapsed) => {
    state.sideMenuCollapsed = collapsed;
  } ,
  COLLAPSED_SIDE_MENU_DRAWER: (state, collapsed) => {
    state.sideMenuDrawerCollapsed = collapsed;
  } ,
  CHANGE_PAGE_LOADING : (state,payload) => {
    state.pageLoading = payload.isLoading
    if (!payload.isLoading) {
      state.blockPageLoading = false
    } else {
      state.blockPageLoading = payload.block
    }
  } ,

  // CHANGE_SCREEN_LOADING : (state,payload) => {
  //   state.screenLoading = payload.isLoading
  // } ,
  CLEAR_BREADCRUMB_PARAMS : (state,payload) => {
    state.breadcrumbParams = {}
  } ,
  ADD_BREADCRUMB_PARAMS : (state,payload) => {
    state.breadcrumbParams = Object.assign({},state.breadcrumbParams,payload)
  } ,
}

export const actions = {
  init({state,commit}) {
    commit('INITIAL_STORE')
  } ,
  changeDeviceType({commit},device) {
    commit('CHANGE_DEVICE_TYPE',device)
    if (device === DEVICE_TYPE.MOBILE || device === DEVICE_TYPE.TABLET)
      commit('COLLAPSED_SIDE_MENU_DRAWER',true)
    else
      commit('COLLAPSED_SIDE_MENU',false)
  },
  collapsedSideMenu({commit},collapsed) {
    commit('COLLAPSED_SIDE_MENU',collapsed)
  } ,
  collapsedSideMenuDrawer({commit},collapsed) {
    commit('COLLAPSED_SIDE_MENU_DRAWER',collapsed)
  },
  showPageLoading({state,commit},block=false) {
    if (state.pageLoading)
      return
    NProgress.start()
    commit('CHANGE_PAGE_LOADING',{isLoading: true, block:block})
  } ,
  hidePageLoading({commit}) {
    NProgress.done()
    commit('CHANGE_PAGE_LOADING',{isLoading: false, block:false})
  } ,
  changeDashboardShowOnlyCompany({commit},value) {
    commit("CHANGE_DASHBOARD_SHOW_ONLY_COMPANY",value)
  } ,

  // showScreenLoading({state,commit}) {
  //   if (state.screenLoading)
  //    return
  //    commit('CHANGE_SCREEN_LOADING',{isLoading: true})
  // } ,
  // hideScreenLoading({commit}) {
  //    commit('CHANGE_SCREEN_LOADING',{isLoading: false})
  // } ,
  clearBreadcrumbParams({commit},params = null) {
    commit('CLEAR_BREADCRUMB_PARAMS',params);
  } ,
  addBreadcrumbParams({commit},params) {
    commit('ADD_BREADCRUMB_PARAMS',params);
  } ,
}

export const getters = {
  changeDefaultPathByDevice(state) {
    switch (state.device) {
      case DEVICE_TYPE.TABLET:
        return 'onfield/create'
      default:
        return 'dashboard'
    }
  },
}
