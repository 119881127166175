/*
 * Loading initial configuration of external library
 */

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import storage from "vue-ls"
import LoadScript from "vue-plugin-load-script";

import VueDayjs from "vue-dayjs-plugin"
import dayjsRelativeTime from "dayjs/plugin/relativeTime"
import Authorize from "@src/authorize/index.js"
import VueLazyLoad from 'vue-lazyload'

import schema from 'async-validator'
import NProgress from "nprogress"
import {axiosInit} from "@utils/axiosUtil"
import { isStringEmpty ,isVarEmpty,toNumber} from "@utils/stringUtil"

import numeral from "numeral"
import ServerRequestError from "@utils/errors/ServerRequestError";

function vueConfiguration() {
	Vue.config.productionTip = false;
	Vue.config.ignoredElements = ['ion-icon']

	Vue.config.errorHandler = function (err, vm, info) {
		// TODO: Vue Global Handler
		// `info` is a Vue-specific error info, e.g. which lifecycle hook
		// the error was found in. Only available in 2.2.0+
		console.log("VUE Global Error ["+info+"]" + err);
		console.error(err);
		if (vm) {
			if (err instanceof ServerRequestError)
				vm.$pageError({name : 'error_timeout'},{error: err})
			else
				vm.$pageError({name: 'error_unexpected'},{error : err})
		}
	}

	// Suppress antd validation message
	schema.warning = function(){};
}
export function vuePlugin() {
	if (Vue.ls) {
		return
	}

	Vue.use(VueI18n)

	Vue.use(VueRouter)
	Vue.use(Vuex)
	Vue.use(VueMeta, {
		// The component option name that vue-meta looks for meta info on.
		keyName: 'page',
	})
	Vue.use(storage,{
		namespace: 'tj_',
		name: 'ls',
		storage: 'local',
	})

	Vue.use(Authorize)

	Vue.use(VueDayjs)
	Vue.dayjs.extend(dayjsRelativeTime)

	Vue.use(VueLazyLoad)
	Vue.use(LoadScript)
}

function vueFilters() {
	Vue.filter('emptyCheck',function(value,display=null){
		if (isVarEmpty(value)) {
			// TODO How to make it internationalize ???
			if (display === null)
				return "ไม่ระบุ"
			else
				return  display
		} else {
			return value;
		}
	})
	Vue.filter('price', function(value,hideUnit=false) {
		const d = toNumber(value)
		if (isNaN(d)) {
			return "-"
		} else {
			// TODO How to make it internationalize ???
			const out = numeral(d).format('0,0.00');
			if (hideUnit)
				return out;
			else
				return out+" บาท"
		}
	})
	Vue.filter('integer',function(value,display=null) {
		const d = toNumber(value)
		if (isNaN(d)) {
			return display === null ? "ไม่ระบุ" : display
		} else {
			return d
		}
	})
	Vue.filter('date' ,function(value,format='LL',empty=''){
		if (value === null || value === undefined)
			return empty
		else if (format === null)
			return Vue.dayjs(value)
		else
			return Vue.dayjs(value).format(format);
	})
}
function vueExtends() {
	Vue.prototype.$tc_my = function(key,count,showGroup=true) {
		if (count === undefined || count === null || count === 0) {
			return this.$t(key+".zero")
		} else {
			if (showGroup) {
				count = numeral(count).format('0,0');
			}
			return this.$t(key,{count});
		}
	}
	Vue.prototype.$tt = function(key,field=null,params={}) {
		if (field)
			return this.$t(key,{
				...params ,
				field : this.$t(field)
				})
		else
			return this.$t(key,{
				...params ,
				field : ''
			})
	}
	Vue.prototype.$tenum = function(type,value,emptyDisplay=null) {
		if (isStringEmpty(type) || isStringEmpty(value)) {
			return emptyDisplay === null ? this.$t('common.unspecify') : emptyDisplay;
		}
		const key = 'enum.'+type+'.'+value;
		const rtn = this.$t(key);
		return (rtn === key) ? (emptyDisplay === null ? this.$t('common.unspecify') : emptyDisplay) : rtn;
	}
	Vue.prototype.$notEmpty = function(value) {
		return !isVarEmpty(value)
	}
	Vue.prototype.$open = function(route,target='_self',reload=false) {
		if (target == '_self') {
			if (reload) {
				window.open(this.$router.resolve(route).href,target)
			} else {
				this.$router.push(route)
			}
		} else {
			window.open(this.$router.resolve(route).href,target)
		}
	}

	window.eventBus = new Vue();
	// Using in MyRouterViewWrapper
	Vue.prototype.$pageError = function(location,payload) {
		window.eventBus.$emit('page-error', location, payload);
	}
}
function vueDirectives() {
	const trimDirective = (el,binding,vnode) => {
		if (vnode.children) {
			let html = '';
			for(const child of vnode.children) {
				if (!child.tag) {
					html += child.text.trim()
				} else if (child.elm && child.elm.outerHTML) {
					html += child.elm.outerHTML.trim()
				}
			}
			el.innerHTML = html
		}
	}
	Vue.directive('trim', {
			bind : trimDirective ,
			componentUpdated :trimDirective ,
		}
	)


}

function othersConfiguration() {
	NProgress.configure({ showSpinner: false });
}

function init() {
	vueConfiguration();
	vuePlugin();
	axiosInit();
	vueExtends();
	vueFilters();
	vueDirectives();
	othersConfiguration();

	return true
}
init();
