<i18n locale="th" lang="yaml" src="@i18n/common/menu.th.yaml">
</i18n>

<template>
	<div class="tunjai-layout-sidemenu-container">
		<div class="app-brand">
			<!-- <ImageLoader
				src="@assets/images/logo_tg_128.png"
				size="small"
				border-type="circle"
				class="brand-logo"/> -->
			<div :class="menuCollapsed ? 'brand-title-collapsed' : 'brand-title'">DECK</div>
		</div>

		<div v-show="!menuCollapsed" class="company-profile">
			<div class="card-image-with-text-box">
				<div class="card-image">
					<ImageLoader :src="currentCompany.image_url" size="small" border-type="round" class="image-round">
						<a-icon type="shop" />
					</ImageLoader>
				</div>
				<div class="card-content">
					<div class="card-title">
						<my-router-link name="company/view" :param-value="currentCompany.id" auth-action="read" auth-module="company">
							{{ currentCompany.name }}
						</my-router-link>
					</div>
					<div class="card-description">
						{{ $t('user.display_role', { role: $tenum('user_role', `${currentUser.user_role}`) }) }} <br />
					</div>
				</div>
			</div>
		</div>

		<a-menu
			class="tunjai-menu"
			mode="inline"
			theme="light"
			:selected-keys="selectedKeys"
			:default-open-keys="openKeys"
			:inline-collapsed="menuCollapsed"
			@click="menuClick">
			<a-menu-item v-if="!isMobile" key="menu.dashboard">
				<my-router-link id="sidemenu_dashboard_link" name="dashboard" no-param>
					<a-icon type="layout" />
					<span>{{ $t('menu.dashboard') }}</span>
				</my-router-link>
			</a-menu-item>
			<a-menu-item v-if="!isMobile" key="menu.ticket.create">
				<my-router-link id="sidemenu_create_ticket_link" name="ticket/create" no-param>
					<a-icon type="plus" />
					<span>{{ $t('menu.ticket.create') }}</span>
				</my-router-link>
			</a-menu-item>
			<a-menu-item v-if="!isMobile" key="menu.service.create">
				<my-router-link id="sidemenu_create_service_link" name="service/create" no-param>
					<a-icon type="plus" />
					<span>{{ $t('menu.service.create') }}</span>
				</my-router-link>
			</a-menu-item>
			<a-menu-item v-if="!isMobile" key="menu.drone.drone">
				<my-router-link id="sidemenu_search_drone_link" name="drone/search" no-param>
					<DroneIcon class="anticon" />
					<span>{{ $t('menu.drone.drone') }}</span>
				</my-router-link>
			</a-menu-item>
			<a-submenu v-if="canAccessOnsiteFeature" id="sidemenu_onfield_menu" key="menu.onfield">
				<span slot="title" >
					<ProgressWrench class="anticon" />
					<span>{{ $t('menu.onfield') }}</span>
				</span>
				<a-menu-item key="menu.onfield.create">
					<my-router-link name="onfield/create" no-param>
						<a-icon type="plus" />
						<span>{{ $t('menu.onfield.create') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu.onfield.resume">
					<my-router-link name="onfield/resume" no-param>
						<a-icon type="tool" />
						<span>{{ $t('menu.onfield.resume') }}</span>
					</my-router-link>
				</a-menu-item>
			</a-submenu>
			<a-submenu v-if="!isMobile" id="sidemenu_admin_menu" key="menu.admin">
				<span slot="title" >
					<AdminIcon class="anticon" />
					<span>{{ $t('menu.admin') }}</span>
				</span>
				<a-menu-item key="menu.service">
					<my-router-link id="sidemenu_admin_service_link" name="admin/service_list" no-param>
						<a-icon type="tool" />
						<span>{{ $t('menu.admin.service.repair_list') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu.ticket">
					<my-router-link id="sidemenu_admin_ticket_link" name="admin/ticket_list" no-param>
						<a-icon type="tool" />
						<span>{{ $t('menu.admin.ticket') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="$can('list', 'drone')" key="menu.admin.drone">
					<my-router-link id="sidemenu_admin_dronelist_link" name="admin/drone_list" no-param>
						<DroneIcon class="anticon" />
						<span>{{ $t('menu.admin.drone') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="$can('list', 'changeRequest')" key="menu.admin.drone_change_request">
					<my-router-link id="sidemenu_admin_drone_change_link" name="admin/drone_change_request" no-param>
						<DroneIcon class="anticon" />
						<span>{{ $t('menu.admin.drone_change_request') }}</span>
					</my-router-link>
				</a-menu-item>

				<a-menu-item v-if="isManagement" key="menu.admin.manufacture">
					<my-router-link id="sidemenu_admin_manu_facture_link" name="admin/manufacture" no-param>
						<ManufactureIcon class="anticon" />
						<span>{{ $t('menu.admin.manufacture') }}</span>
					</my-router-link>
				</a-menu-item>
			</a-submenu>
			<a-submenu v-if="!isMobile" id="sidemenu_report_menu" key="menu.report">
				<span slot="title">
					<a-icon type="bar-chart" />
					<span>{{ $t('menu.report') }}</span>
				</span>
				<a-menu-item key="menu.report.part_usage">
					<my-router-link id="sidemenu_part_usage_report_link" name="report/part_usage" no-param>
						<span>{{ $t('menu.report.part_usage') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu.report.claim_request">
					<my-router-link id="sidemenu_claim_part_request_link" name="report/claim_request" no-param>
						<span>{{ $t('menu.report.claim_request') }}</span>
					</my-router-link>
				</a-menu-item>
			</a-submenu>

			<a-submenu id="sidemenu_drone_menu" key="menu.drone">
				<span slot="title">
					<DroneIcon class="anticon" />
					<span>{{ $t('menu.drone') }}</span>
				</span>

				<a-menu-item key="menu.drone.drone_model">
					<my-router-link id="sidemenu_drone_model_link" name="drone_model/list" no-param>
						<span>{{ $t('menu.drone.drone_model') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu.drone.part">
					<my-router-link id="sidemenu_part_list_link" name="part/list" no-param>
						<span>{{ $t('menu.drone.part') }}</span>
					</my-router-link>
				</a-menu-item>
			</a-submenu>
			<a-submenu id="sidemenu_system_menu" key="menu.system">
				<span slot="title">
					<ion-icon name="settings-outline" class="anticon" />
					<span>{{ $t('menu.system') }}</span>
				</span>
				<a-menu-item key="menu.system.company">
					<my-router-link id="sidemenu_system_company_link" name="company/list" no-param>
						<span>{{ $t('menu.system.company') }}</span>
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu.system.firmware">
					<my-router-link id="sidemenu_system_firmware_link" name="firmware/list" no-param>
						<span>{{ $t('menu.system.firmware') }}</span>
					</my-router-link>
				</a-menu-item>
			</a-submenu>
			<a-menu-divider />
		</a-menu>

		<footer v-show="!menuCollapsed">HiveGround © {{ copyright }}</footer>
	</div>
</template>

<script>
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import LayoutMixin from '@mixins/LayoutMixin.vue'
import ImageLoader from '@components/common/ImageLoader.vue'
import DroneIcon from '@components/icon/DroneIcon.vue'
import AdminIcon from 'vue-material-design-icons/AccountCog.vue'
import ProgressWrench from 'vue-material-design-icons/ProgressWrench.vue'
import ManufactureIcon from 'vue-material-design-icons/Factory.vue'
import { Menu } from 'ant-design-vue'
import { mapActions } from 'vuex'
import get from 'lodash/get'
export default {
	components: {
		ImageLoader,
		'a-menu': Menu,
		'a-menu-item': Menu.Item,
		'a-submenu': Menu.SubMenu,
		'a-menu-divider': Menu.Divider,
		DroneIcon,
		AdminIcon,
		ProgressWrench,
		ManufactureIcon,
	},
	mixins: [LoginInfoMixin, LayoutMixin],
	props: {
		menuCollapsed: {
			type: Boolean,
		},
		isDrawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			openKeys: [],
			selectedKeys: [],
		}
	},
	computed: {
		isMobile() {
   		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    		return true
   		} else {
    		return false
   		}
	 	},
		isManagement() {
			return this.$isManagement(this.currentCompany.company_type)
		},
		canViewManufacture() {
			return this.$authorize('read', 'manufacture')
		},
		canAccessOnsiteFeature() {
			const companyType = this.currentCompany.company_type
			return !(this.$isExternalDealer(companyType) || this.$isServiceCenter(companyType))
		}
	},
	watch: {
		$route() {
			this.updateMenus()
		},
	},
	mounted() {
		this.updateMenus()
	},
	methods: {
		...mapActions('app', ['collapsedSideMenuDrawer']),
		updateMenus() {
			let open = null
			let select = null
			for (const route of this.$route.matched) {
				open = get(route, 'meta.menu.open', open)
				select = get(route, 'meta.menu.select', select)
			}
			// if (this.showManagement) {
			// 	this.openKeys.push("menu.admin")
			// }
			if (open && this.openKeys.indexOf(open) < 0) {
				this.openKeys.push(open)
			}
			if (select) this.selectedKeys = [select]
		},
		handleMenuOpenChange(openKeys) {},
		menuClick() {
			if (this.isDrawer) {
				this.collapsedSideMenuDrawer(true)
			}
		},
	},
}
</script>

<style lang="less" scoped>
.app-brand {
	position: relative;
	padding: 8px 0;
	overflow: hidden;
	white-space: nowrap;

	.brand-logo {
		margin: 0 16px;
	}
	.brand-title {
		display: block;
		margin-top: 16px;
		text-align: center;
		align-items: center;
		line-height: 48px;
		font-size: 2em;
		font-weight: 600;
		color: @tigerdrone-logo-color;
		font-family: @font-family-title;
	}
	.brand-title-collapsed {
		margin-top: 0 16px;
		text-align: center;
		align-items: center;
		line-height: 48px;
		font-size: 1.2em;
		font-weight: 600;
		color: @tigerdrone-logo-color;
		font-family: @font-family-title;
	}
}
.company-profile {
	background-color: @primary-4;
	color: @white;
	font-family: @font-family-title;
	padding: 20px 12px;
	margin: 20px auto 20px;
	border-radius: @border-radius-base;
	width: calc(@layout-sidemenu-width - 36px);
	.card-title {
		font-size: 1.2em;
		font-weight: normal !important;
		margin-bottom: 2px;
		margin-left: 4px;
		a {
			color: @white;
			text-decoration: none !important;
		}
	}
	.card-image .image-loader {
		background-color: white;
	}
}

.tunjai-menu {
	margin-bottom: 24px;
	.ant-menu-item {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		&:hover {
			background-color: @secondary-1;
		}
	}
}
footer {
	font-size: 0.8em;
	color: @gray-8;
	text-align: right;
	padding-right: 8px;
	padding-bottom: 8px;
}
</style>
