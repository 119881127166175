<script>
import {ConfigProvider} from "ant-design-vue"
import en from 'ant-design-vue/lib/locale/en_US';
import th from 'ant-design-vue/lib/locale/th_TH';
import DeviceMixin from '@mixins/DeviceMixin.vue'
import { mapState } from 'vuex';

export default {
	page() {
		const appName = this.$t('common.application.title')
		return {
			titleTemplate(titleChuck) {
				return titleChuck ? `${titleChuck} - ${appName}` : appName;
			}
		}
	},
	components : {
		ConfigProvider ,
	} ,
	mixins: [DeviceMixin],
	computed : {
    copyright() {
      return new Date().getFullYear();
    } ,
		locale() {
			return (this.$i18n.locale === 'th' ? th : en);
		} ,
		...mapState('app',['sideMenuCollapsed','sideMenuDrawerCollapsed'])
	}
}
</script>
