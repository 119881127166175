import assign from "lodash/assign"
import pick from "lodash/pick"
import keys from "lodash/keys"
import get from "lodash/get";
import { sortEnumValue,isStringEmpty } from "./stringUtil";
import isObject from "lodash/isObject"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"

export const technician = 3
export function replaceProperty(src,data) {
	if (data) {
		assign(src,pick(data,keys(src)));
	}
	return src;
}

/**
 * Simple Equals , Compare only property of source obj
 */
export function equalsObject(srcObj,newObj) {
	if (srcObj === null)
		return newObj === null
	for(const key in srcObj){
		const currentVal = srcObj[key]
		const newVal = newObj[key]
		if (currentVal !== newVal)
			return false
	}
	return true
}
export function clearProperty(src,useUndefined=false) {
	for(const key of keys(src)) {
		src[key] = useUndefined ? undefined : null;
	}
}

export function sortEnum(list,a,b,attribute) {
	return sortEnumValue(list,get(a,attribute,'__invalidxxx__'),get(b,attribute,'__invalidxxx__'))
}

export function lookup(bucket,path,key,defaultVal={}) {
	if (isStringEmpty(path) || !key)
		return defaultVal;
	const _path = path+"."+key
	return get(bucket,_path,defaultVal);
}

export function arrayPagination(list,page,pageSize) {
	const start = ((page - 1) * pageSize)
	if (start >= list.length) {
		return []
	} else {
		let end = start + pageSize
		end = (end >= list.length) ? list.length : end
		return list.slice(start,end)
	}
}

/*
 * Utility for basic object
 */
export function emptyDrone() {
	return {
		drone : {} , lot : {} ,model : {} , owner : {} ,firmware : {}, connect : {},
	}
}
export function fulfillDrone(drone,bucket) {
	if (drone.id) {
		const lot = lookup(bucket,'lots',drone.drone_lot_id);
		return {
			id : drone.id,
			drone : drone,
			lot : lot ,
			model : lookup(bucket,'models',lot.drone_model_id) ,
			owner : lookup(bucket,'customers',drone.customer_id) ,
			firmware : lookup(bucket,'firmwares',drone.firmware_id) ,
			connect : {},
		}
	} else {
		return emptyDrone()
	}
}
export function emptyUser() {
	return {user:{},company:{}}
}
export function fulfillUser(user,bucket) {
	if (user.id) {
		return {
			id : user.id,
			user : user,
			company : lookup(bucket,"companies",user.company_id)
		}
	} else {
		return emptyUser()
	}
}
export function emptyTicket() {
	return {
		ticket : {},reportBy : emptyUser() ,
		createdBy : emptyUser() ,updatedBy : emptyUser() ,
		droneData : emptyDrone()
	}
}

export function fulfillTicket(ticket,bucket) {
	if (ticket.id) {
		return {
			id : ticket.id,
			ticket : ticket,
			reportBy : fulfillUser(lookup(bucket,"users",ticket.report_by_id),bucket) ,
			createdBy : fulfillUser(lookup(bucket,"users",ticket.created_by_id),bucket) ,
			updatedBy : fulfillUser(lookup(bucket,"users",ticket.updated_by_id),bucket) ,
			droneData : fulfillDrone(lookup(bucket,"drones",ticket.drone_id),bucket)
		}
	} else {
		return emptyTicket()
	}
}

export function emptyTicketComment() {
	return {comment : {} , createdBy : emptyUser()}
}
export function fulfillTicketComment(comment,bucket) {
	if (comment.id) {
		return {
			id : comment.id,
			comment : comment ,
			createdBy : fulfillUser(lookup(bucket,"users",comment.created_by_id),bucket) ,
		}
	} else {
		return emptyTicketComment()
	}
}
export function emptyDroneNote() {
	return {note : {} , createdBy : emptyUser(),updatedBy : emptyUser()}
}
export function fulfillDroneNote(note,bucket) {
	if (note.id) {
		return {
			id : note.id,
			note ,
			createdBy : fulfillUser(lookup(bucket,"users",note.created_by_id),bucket) ,
			updatedBy : fulfillUser(lookup(bucket,"users",note.updated_by_id),bucket) ,
		}
	} else {
		return emptyDroneNote()
	}
}

export function fulfillDroneHistory(history,bucket) {
	if (history.key) {

		switch(history.type) {
			case 'ticket':
				return {
					...history,
					actionBy : fulfillUser(lookup(bucket,"users",history.action_by_id),bucket) ,
				}
			case 'service_refer': {
				const data = history.data;
				data.from_company = bucket.companies[data.from_company_id];
				data.to_company = bucket.companies[data.to_company_id];
				return {
					...history,
					data: data
				}
			}
			default:
				return {
					...history,
					actionBy : fulfillUser(lookup(bucket,"users",history.action_by_id),bucket) ,
				}
		}
	} else {
		return {
			actionBy : emptyUser()
		}
	}
}

export function emptyServiceCardData() {
	return {
		service : {} , drone : {} , company : {} ,
		openBy : emptyUser() ,
		createdBy : emptyUser() ,updatedBy : emptyUser() ,
	}
}
export function fulfillServiceCardData(service,bucket) {
	if (service.id) {
		return {
			id : service.id ,
			service : service,
			drone : lookup(bucket,'drones',service.drone_id) ,
			company : lookup(bucket,"companies",service.company_id) ,
			openBy : fulfillUser(lookup(bucket,"users",service.open_by_id),bucket) ,
			createdBy : fulfillUser(lookup(bucket,"users",service.created_by_id),bucket) ,
			updatedBy : fulfillUser(lookup(bucket,"users",service.updated_by_id),bucket) ,
		}
	} else {
		return emptyServiceCardData()
	}
}

export function emptyServicePartData() {
	return {
		newPart : {} , oldPart : {} , drone : {} , service : {},
	}
}
export function fulfillServicePartData(servicePart,bucket) {
	if (servicePart.id) {
		return {
			id : servicePart.id,
			servicePart : servicePart,
			newPart : lookup(bucket,'parts',servicePart.new_part_id) ,
			oldPart : lookup(bucket,'parts',servicePart.old_part_id) ,
			drone : lookup(bucket,'drones',servicePart.drone_id) ,
			service : lookup(bucket,'services' ,servicePart.service_id) ,
		}
	}
}

export function fulfullServiceStatusLog(log,bucket) {
	if (log.updated_by_id) {
		return {
			...log ,
			updatedBy : fulfillUser(lookup(bucket,'users',log.updated_by_id),bucket)
		}
	} else {
		return {
			...log ,
			updatedBy : emptyUser()
		}
	}
}

export function transformToJsonProp(model,dataProp,jsonProp) {
	model[jsonProp] = null
	if (dataProp in model) {
		if (model[dataProp] != null)
			model[jsonProp] = JSON.stringify(model[dataProp])
		delete model[dataProp]
	}
}

function _deepKeys(obj,path=undefined) {
	if (!isObject(obj))
		return path
	const result = []
	for (const prop in obj) {
		const myPath = path ? path+"."+prop : prop
		if (isObject(obj[prop])) {
			result.push(..._deepKeys(obj[prop],myPath))
		} else {
			result.push(myPath)
		}
	}
	return result
}
export function replaceDeepProperty(original,data) {
	const keys = _deepKeys(original)
	if (keys) {
		for(const prop of keys) {
			const val = get(data,prop)
			if (val !== undefined) {
				set(original,prop,val)
			}
		}
	}
}
export function copyDeep(original) {
	return cloneDeep(original)
}

export function emptyService() {
	return {
		service : {},reportBy : emptyUser() ,
		createdBy : emptyUser() ,updatedBy : emptyUser() ,
		droneData : emptyDrone()
	}
}
export function fulfillService(service,bucket) {
	if (service.id) {
		return {
			id : service.id,
			service : service,
			reportBy : fulfillUser(lookup(bucket,"users",service.open_by_id),bucket),
			createdBy : fulfillUser(lookup(bucket,"users",service.created_by_id),bucket),
			updatedBy : fulfillUser(lookup(bucket,"users",service.updated_by_id),bucket),
			droneData : fulfillDrone(lookup(bucket,"drones",service.drone_id),bucket)
		}
	} else {
		return emptyService()
	}
}
