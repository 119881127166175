import VueRouter from 'vue-router'
import NProgress from 'nprogress/nprogress'
import store from '@store/store'
import routes from './routes'
import Vue from "vue"
import ServerRequestError from "@utils/errors/ServerRequestError.js"
import abilities from '../authorize/abilities'


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			if (document.getElementById('tunjai_layout_content')) {
				document.getElementById('tunjai_layout_content').scrollTo({top : 0})
			} else {
				return {
					x : 0 , y : 0
				}
			}
		}
	},
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
	const currentLocation = routeTo.fullPath
	const currentCompanyType = store.state.auth.currentCompany.company_type
	// If this isn't an initial page load...
	NProgress.done()

	if (routeFrom.name !== null) {
		// Start the route progress bar.
		NProgress.start()
	}



	// Check if auth is required on this route
	// (including nested routes).
	const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

	// If auth isn't required for the route, just continue.
	if (!authRequired) {
		return next();
	}

	// If auth is required and the user is logged in...
	if (store.getters['auth/isLoggedIn']) {
		let checkRoles = []
		routeTo.matched.forEach((route) => {
			if (route.meta.roles) {
				checkRoles = route.meta.roles;
			}
		})

		if (!Vue.$checkRoles(checkRoles)) {
			console.log("Unauthorized Access : Invalid Role "+checkRoles);
			next({name: 'error_forbidden',query : {from : currentLocation}})
			return
		}

		for (const route of routeTo.matched) {
			if (route.meta.onfieldAccessRequired && (Vue.$isExternalDealer(currentCompanyType) || Vue.$isServiceCenter(currentCompanyType)))
				next({name : 'error_forbidden'})

			if (route.meta && route.meta.permission) {
				const permission = route.meta.permission;
				const params = {};
				if (permission.paramMaps) {
					for(const param in permission.paramMaps) {
						params[param] = routeTo.params[permission.paramMaps[param]]
					}
				}
				if (abilities.cannot(permission.action,permission.module)) {
					console.log("Unauthorized Access : Invalid Permission "+permission);
					next({name: 'error_forbidden',query : {from : currentLocation}})
					NProgress.done()
					return
				}
			}
		}



		// Validate the local user token...
		// store.dispatch('app/showScreenLoading')
		return store.dispatch('auth/validate').then((valid) => {
			// Then continue if the token still represents a valid user,
			// otherwise redirect to login.
			if (valid) {
				next();
			} else {
				redirectToLogin()
			}
		}).catch((error) => {
			if (error instanceof ServerRequestError) {
				next({name : 'error_timeout', params : {error : error} , query : {from : currentLocation}})
			} else {
				next({name : 'error_unexpected' ,params : {error : error} , query : {from : currentLocation}})
			}
		})
		// .finally (()=>{
		// 	store.dispatch('app/hideScreenLoading')
		// })
	}

	// If auth is required and the user is NOT currently logged in,
	// redirect to login.
	redirectToLogin()

	function redirectToLogin() {
		// Pass the original route to the login component
		next({
			name: 'login',
			query: {
				redirect: routeTo.fullPath
			}
		})
	}
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// For each matched route...
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							if (routeFrom.name === args[0].name) {
								// Complete the animation of the route progress bar.
								NProgress.done()
							}
							// Complete the redirect.
							next(...args)
							reject(new Error('Redirected'))
						} else {
							resolve()
						}
					})
				} else {
					// Otherwise, continue resolving the route.
					resolve()
				}
			})
		}
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		return
	}

	// If we reach this point, continue resolving the route.
	next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
	// Complete the animation of the route progress bar.
	NProgress.done()
})

export default router
