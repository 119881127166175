<script>
import ApiError from '@utils/errors/ApiError'
import { mapState, mapActions } from 'vuex'
import ServerRequestError from '@utils/errors/ServerRequestError'
export default {

	computed : {
		...mapState('app',['pageLoading']) ,
	},
	methods: {
		...mapActions('app',[
			'showPageLoading',
			'hidePageLoading',
			'addBreadcrumbParams',
			'clearBreadcrumbParams' ,
			]) ,

		fetchError(error) {
			// TODO Manage page error
			if (error instanceof ApiError) {
				switch (error.code) {
					case 404 :
						this.$pageError({name:'error_notfound'},{error})
						break;
					case 403 :
						this.$pageError({name:'error_forbidden'},{error})
						break;
					default	:
						this.$pageError({name:'error_unexpected'},{error})
						break;
				}
			} else if ( error instanceof ServerRequestError) {
				this.$pageError({name:'error_timeout'},{error})
			} else {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error),4)
			}
		}
	}
}
</script>
