
<i18n locale="th" lang="yaml" >
menu.profile: "ข้อมูลผู้ใช้งาน"
menu.logout: "ออกจากระบบ"
menu.password : "เปลี่ยนรหัสผ่าน"
menu.privacy.policy : "Privacy Policy"
logout.confirm.title: "ออกจากระบบ ?"
logout.confirm.content: "คุณ {name} ต้องการออกจากระบบ @:common.application.title ?"
logout.success.title : "ออกจากระบบเรียบร้อย"
logout.success.content : "คุณ {name} ออกจากระบบ @:common.application.title เรียบร้อย"

menu.line_unbind : "ยกเลิกบัญชี LINE"
line_unbind.confirm.title : "ยกเลิกการผูกบัญชีกับ LINE ?"
line_unbind.confirm.content : "คุณ {name} ต้องการยกเลิกการผูกบัญชีกับ LINE Account {lineName} ?"
line_unbind.success : "ทำการยกเลิกการผูกบัญชีกับ LINE เรียบร้อย"

menu.line_bind : "ผูกบัญชีกับ LINE"
line_bind.confirm.title: "ผูกบัญชีกับ LINE Account ?"
line_bind.confirm.content: "คุณ {name} ต้องการผูกบัญชีผู้ใช้งานของคุณกับ LINE Account ? (เพื่อช่วยในการสื่อสารมากขึ้น)"

</i18n>

<template>
	<div>
		<a-dropdown
			id="usermenu_usermenu_dropdown"
			class="user-menu-dropdown"
			:trigger="['click']"
			overlay-class-name="user-menu-overlay">
			<a-menu slot="overlay" class="user-menu">
				<a-menu-item key="menu_info" class="user-profile-menu-item">
					<div class="user-profile">
						<div class='card-image-with-text-box'>
							<div class='card-image'>
								<ImageLoader
									:src="currentUser.image_url"
									:show-placeholder-loading="true"
									:hide-loading="true"
									size="small"
									border-type="circle">
									<a-icon type="user" />
								</ImageLoader>
							</div>
							<div class='card-content'>
								<div class='card-title'>
									{{currentUser.full_name}}
								</div>
								<div class='card-description'>
									{{currentCompany.name}}
								</div>
							</div>
						</div>
					</div>
				</a-menu-item>

				<a-menu-item key="menu_profile" >
					<my-router-link id="usermenu_profile_link" name="user/view" no-param>
					<a-icon type="user"/>
					{{$t('menu.profile')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="$notEmpty(currentLineAccount.line_id)" key="menu.line_unbind">
					<a @click="unbindLineAccount">
						<img src="@assets/images/logo_line_menu.png" width="16" height="16"/> {{$t('menu.line_unbind')}}
					</a>
				</a-menu-item>
				<a-menu-item v-else key="menu.line_bind">
					<a id="usermenu_line_account_link" @click="bindLineAccount">
						<img src="@assets/images/logo_line_menu.png" width="16" height="16"/> {{$t('menu.line_bind')}}
					</a>
				</a-menu-item>
				<a-menu-item key="menu_password" >
					<my-router-link id="usermenu_change_password_link" name="user/change_password" no-param>
					<ion-icon name="key-outline" class="anticon" />

					{{$t('menu.password')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu_privacy_policy" >
					<my-router-link id="usermenu_privacy_policy_link" name="user/privacy_policy" no-param >
					<ion-icon name="key-outline" class="anticon" />
					{{$t('menu.privacy.policy')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="menu_logout" >
					<a id="usermenu_logout_link" href="javascript:void(0)" @click="handleLogout">
						<a-icon type="logout"/>
						{{$t('menu.logout')}}
					</a>
				</a-menu-item>

			</a-menu>

			<div class="user-menu-trigger">
				<ImageLoader :src="currentUser.image_url" size="tiny" border-type="circle" class="user-avatar">
					<a-icon type="user" />
				</ImageLoader>
				<span>{{currentUser.username}}</span>
			</div>
		</a-dropdown>
	</div>
</template>

<script>
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import PageMixin from "@mixins/PageMixin.vue"
import ImageLoader from '@components/common/ImageLoader.vue'
import {basicAxios} from '@utils/axiosUtil.js'
import axios from "axios"
import {Dropdown,Menu} from "ant-design-vue"
import { mapActions } from 'vuex'
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ImageLoader ,
		"a-dropdown" : Dropdown ,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	},
	mixins : [LoginInfoMixin,PageMixin] ,
	methods : {
		...mapActions('auth',['logout','updateCurrentLineAccount','updateCurrentUser']),
		bindLineAccount() {
			this.$confirm({
				title : this.$t('line_bind.confirm.title') ,
				content : this.$t('line_bind.confirm.content',{ "name": name}) ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk : () => {
					this.showPageLoading()
					basicAxios.get("/api/auth/line-login-url",{params:{mode:'bind'}}).then((response)=>{
						const url = response.data.data.url
						window.location = url
					}).catch((error) => {
						this.$message.error(ApiError.getDisplayErrorMessage(this,error))
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		unbindLineAccount() {
			if (!this.$notEmpty(this.currentLineAccount.line_id)) {
				return
			}
			this.$confirm({
				title : this.$t('line_unbind.confirm.title') ,
				content : this.$t('line_unbind.confirm.content',{ "name": name,'lineName' : this.currentLineAccount.display_name}) ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk : () => {
					this.showPageLoading()
					axios.post("/api/users/unbind-line-account").then((response)=>{
						const user = response.data.data.user
						this.updateCurrentLineAccount({})
						this.updateCurrentUser(user)
						this.$message.success(this.$t('line_unbind.success'))
					}).catch((error) => {
						this.$message.error(ApiError.getDisplayErrorMessage(this,error))
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,

		handleLogout() {
			const name = this.currentUser.full_name;
			this.$confirm({
				title : this.$t('logout.confirm.title') ,
				content : this.$t('logout.confirm.content',{ "name": name}) ,
				okText : this.$t('menu.logout') ,
				maskClosable : true,
				onOk : () => {
					const successTitle = this.$t('logout.success.title');
					const successContent = this.$t('logout.success.content',{"name" : name});
					this.logout().then(()=>{
						this.$notification.success({
							message :  successTitle,
							description : successContent,
						});
						this.$router.push({name: "login"});
					})
				} ,
			})
		}
	}
}
</script>

<style lang="less" scoped>
.user-menu-dropdown {
	height : 100%;
	padding : 0 12px;
	cursor: pointer;
	&:hover {
		background-color : @white-hover-bg;
	}
}
.user-menu-trigger {
	height : 100%;
	user-select: none;
	.user-avatar {
		margin-right : 4px;
		vertical-align: middle;
	}
}

.user-menu {
	padding-top : 0;
	padding-bottom : 0;

	.ant-dropdown-menu-item {
		padding : 10px 12px;

		&.user-profile-menu-item {
			padding : 0;
			cursor: default;
		}
	}
}
.user-profile {
	color : @white;
	padding : 24px 16px;
	border-top-left-radius: @border-radius-base;
	border-top-right-radius: @border-radius-base;
	background-image : url("@{image-dir}/bg-user-profile.png");
	background-size : cover;
	width : 300px;
	height : 100px;
}

</style>

<style lang="less">
// Overlay is attach to body then it cannot be used in scoped
.user-menu-overlay {
	box-shadow: @shadow-1-right;
}
</style>
