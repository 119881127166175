import Vuex from "vuex";
import dispatchActionForAllModules from "./dispatch-action-for-all-modules"
import modules from './modules'
import {vuePlugin} from '@src/vue_bootstrap.js'

// Call Vue Plugin again to make sure that Vue Plugin available during init call
vuePlugin();

const store = new Vuex.Store({
  modules,

  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})
export default store;

dispatchActionForAllModules('init');
