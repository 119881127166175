<template>
	<a-layout-header class="tunjai-layout-header">
		<div>
			<a-icon
				v-if="showMenuAsDrawer"
				class="header-btn"
				:type="sideMenuDrawerCollapsed ? 'menu-unfold' : 'menu-fold'"
				@click="toggleDrawer"/>
			<a-icon
				v-else
				class="header-btn"
				:type="sideMenuCollapsed ? 'menu-unfold' : 'menu-fold'"
				@click="toggleSider" />

			<div v-if="isLoggedIn" class="header-menu">
				<my-router-link :name="homePath" class="header-btn" :title="$t('common.page.home.title')" no-param>
					<a-icon type="home" />
				</my-router-link>
				<UserLoginMenu class="header-menu-user"/>

			</div>
		</div>
	</a-layout-header>
</template>

<script>
import LayoutMixin from '@mixins/LayoutMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue';
import UserLoginMenu from './UserLoginMenu.vue'
import { mapActions, mapGetters } from 'vuex';
import {Layout} from "ant-design-vue"
export default {
	components : {
		UserLoginMenu ,
		"a-layout-header" : Layout.Header,
	} ,
	mixins: [LayoutMixin,LoginInfoMixin],
	props : {
		collapsed : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			homePath : 'dashboard',
		}
	} ,
	computed : {
		showMenuAsDrawer() {
			return this.isMobile || this.isTablet
		} ,
	} ,
	mounted() {
		this.homePath = this.changeDefaultPathByDevice()
	} ,
	methods : {
		...mapActions('app',['collapsedSideMenu','collapsedSideMenuDrawer']),
		...mapGetters('app',['changeDefaultPathByDevice']),
		toggleSider() {
			this.collapsedSideMenu(!this.sideMenuCollapsed)
		} ,
		toggleDrawer() {
			this.collapsedSideMenuDrawer(!this.sideMenuDrawerCollapsed)
		}
	}
}
</script>
