<i18n locale="en" lang="yaml">
  page.title : "Page Timeout"
</i18n>
<i18n locale="th" lang="yaml">
page.title : "ไม่สามารถ Load ข้อมูลได้"
error.timeout : "พบปัญหาในการ Load ข้อมูล กรุณารอสักครู่แล้ว ทดลองเข้าใหม่"
</i18n>

<template>
  <ErrorPage
    :code-description="$t('error.timeout')"
    :error-detail="errorDetail"
    :show-refresh="true"
		:location="location">
    <span slot="code">
      time<span class="text-error text-bold">0</span>ut
    </span>
  </ErrorPage>
</template>

<script>
import ErrorPage from "@components/ErrorPage.vue"
import ServerRequestError from '@utils/errors/ServerRequestError'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
    ErrorPage,
  } ,
	props: {
		error : {
			type : null,
			default : () => []
		} ,
		location : {
      type : String,
      default : null
    }
	},
	computed : {
		errorDetail() {
			if (this.error && this.error instanceof ServerRequestError) {
				return this.error.getErrorTitle()
			} else {
				return false
			}
		}
	} ,

}
</script>
