export default class ServerRequestError extends Error {
	constructor(original) {
		super('Cannot connect to server')
		this.original = original;

		this.url = (original.config) ? original.config.url : null
		this.method = (original.config) ? original.method : null
	}

	getErrorTitle() {
		let title = "REQUEST ERROR ";
		if (this.method) {
			title += " "+this.method
		}
		if (this.url) {
			title += " "+this.url
		}
		return title;
	}
}
